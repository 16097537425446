import React from "react";
import { Link } from "react-router-dom";

function Blog2() {
  const data = [
    // {
    //   id: 1,
    //   name: "How Cancer Centers Are Addressing Social Needs",
    //   img: "assets/img/blog/inner_b1.png",
    //   more: "In the journey through cancer treatment, addressing the medical aspects is crucial,",
    //   date: "24th March 2024",
    //    link: "/blogdetails"
    // },
    {
      id: 2,
      name: "Self-Collection Screening for Virus that Causes Cancer.",
      img: "assets/img/blog/inner_b2.png",
      more: "We are thrilled to share exciting news in the field of women’s health:",
      date: "24th April 2024",
      link: "/blogdetails2"
      
    },
    {
      id: 3,
      name: "Palliative care for individuals in their cancer journey.",
      img: "assets/img/blog/inner_b3.png",
      more: "Palliative care grants special attention for those living with a serious illness.",
      date: "24th May 2024",
      link: "/blogdetails3"
    },
    {
      id: 4,
      name: "Understanding When Chemotherapy is Recommended for...",
      img: "assets/img/blog/inner_b3.png",
      more: "Palliative care grants special attention for those living with a serious illness.",
      date: "24th May 2024",
      link: "/blogdetails4"
    },
   
  ];
  return (
    <>
      <section id="blog" className="blog-area p-relative fix pt-90 pb-90">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="section-title center-align mb-50 text-center wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s">
                <h5>Our Blog</h5>
                <h2>Latest Blog &amp; News</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {data.map((item) => (
              <div className="col-lg-4 col-md-6" key={item.id}>
                <div
                  className="single-post2 hover-zoomin mb-30 wow fadeInUp animated"
                  data-animation="fadeInUp"
                  data-delay=".4s"
                >
                  <div className="blog-thumb2">
                    <Link to="/blog">
                      <img src={item.img} alt="img" />
                    </Link>
                  </div>
                  <div className="blog-content2">
                    <div className="date-home">
                      <i className="fal fa-calendar-alt" /> {item.date}
                    </div>
                    <h4>
                      <Link to="/blog">{item.name}</Link>
                    </h4>
                    <p>
                    {item.more}
                    </p>
                    <div className="blog-btn">
                      {/* <Link to="/blog">Read More</Link> */}
                      <Link to={item.link}>Read More</Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog2;
